<template>
  <div class="product_con">
    <div class="title">
      {{ $parent.switchLanguage == "zh" ? "明星产品" : "STAR PRODUCTS" }}
    </div>
    <!-- <div class="subTitle">
      {{
        $parent.switchLanguage == "zh"
          ? "畅销宝货增加供应 满足各路买卖需求"
          : "Increase the supply of popular treasures to meet the needs of all kinds of trading"
      }}
    </div> -->
    <div class="main_con" @mouseenter="on_bot_enter" @mouseleave="on_bot_leave">
      <swiper
        class="main_swiper"
        :class="
          $parent.watchClientWidth > 600 ? 'main_swiper1' : 'main_swiper2'
        "
        ref="mySwipers"
        id="mySwipers"
        :options="backSwiperOption"
      >
        <swiper-slide
          class="swiper-slide"
          :class="
            $parent.watchClientWidth > 600 ? 'swiper-slide1' : 'swiper-slide2'
          "
          v-for="(item, index) in mainList"
          :key="index"
        >
          <!-- -->
          <div
            class="img_con"
            ref="slideItem"
            :class="
              $parent.watchClientWidth > 600
                ? `img_con1 allImg index${index}`
                : `img_con2 allImg index${index}`
            "
          >
            <img
              class="main_img"
              :kyIndex="index"
              :src="$parent.switchLanguage == 'zh' ? item.zhUrl : item.enUrl"
              alt=""
            />
          </div>
          <div class="img_name" :class="`allImg indexa${index}`">
            {{ $parent.switchLanguage == "zh" ? item.zhName : item.enName }}
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div
        class="swiper-button-prev"
        slot="button-prev"
        :class="
          $parent.watchClientWidth > 600
            ? 'swiper-button-prev1'
            : 'swiper-button-prev2'
        "
      ></div>
      <div
        class="swiper-button-next"
        slot="button-next"
        :class="
          $parent.watchClientWidth > 600
            ? 'swiper-button-next1'
            : 'swiper-button-next2'
        "
      ></div>
      <div
        class="swiperNum"
        :class="$parent.watchClientWidth > 600 ? 'swiperNum2' : 'swiperNum1'"
      ></div>
    </div>
    <div class="detail_con">
      <img
        :src="
          $parent.switchLanguage == 'zh'
            ? mainList[activeIndex ? activeIndex : 0].zhSubUrl
            : mainList[activeIndex ? activeIndex : 0].enSubUrl
        "
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
const api = "https://website-1308146035.cos.ap-shanghai.myqcloud.com";
export default {
  name: "product",
  components: {
    swiper,
    swiperSlide
  },
  data() {
    let _this = this;
    return {
      activeIndex: null,
      activeItem: {},
      mainList: [
        {
          zhUrl: api + "/product%2Fled_1.png",
          enUrl: api + "/product%2Fled_1.png",
          zhSubUrl: api + "/product%2Fled_sub1.jpg",
          enSubUrl: api + "/product%2Fled_sub1.jpg",
          zhName: "LED A泡",
          enName: "LED A bubble"
        },
        {
          zhUrl: api + "/product%2Fled_2.png",
          enUrl: api + "/product%2Fled_2.png",
          zhSubUrl: api + "/product%2Fled_sub2.jpg",
          enSubUrl: api + "/product%2Fled_sub2.jpg",
          zhName: "LED GX53 盒仔灯",
          enName: "LED GX53 Box Light"
        },
        {
          zhUrl: api + "/product%2Fled_3.png",
          enUrl: api + "/product%2Fled_3.png",
          zhSubUrl: api + "/product%2Fled_sub3.jpg",
          enSubUrl: api + "/product%2Fled_sub3.jpg",
          zhName: "LED C泡",
          enName: "LED C bubble"
        },
        {
          zhUrl: api + "/product%2Fled_4.png",
          enUrl: api + "/product%2Fled_4.png",
          zhSubUrl: api + "/product%2Fled_sub4.jpg",
          enSubUrl: api + "/product%2Fled_sub4.jpg",
          zhName: "LED G泡",
          enName: "LED G bubble"
        },
        {
          zhUrl: api + "/product%2Fled_5.png",
          enUrl: api + "/product%2Fled_5.png",
          zhSubUrl: api + "/product%2Fled_sub5.jpg",
          enSubUrl: api + "/product%2Fled_sub5.jpg",
          zhName: "LED R泡",
          enName: "LED R bubble"
        },
        {
          zhUrl: api + "/product%2Fled_6.png",
          enUrl: api + "/product%2Fled_6.png",
          zhSubUrl: api + "/product%2Fled_sub6.jpg",
          enSubUrl: api + "/product%2Fled_sub6.jpg",
          zhName: "LED T5 灯管",
          enName: "LED T5 lamp"
        },
        {
          zhUrl: api + "/product%2Fled_7.png",
          enUrl: api + "/product%2Fled_7.png",
          zhSubUrl: api + "/product%2Fled_sub7.jpg",
          enSubUrl: api + "/product%2Fled_sub7.jpg",
          zhName: "LED T8 玻璃灯管",
          enName: "LED T8 glass lamp"
        },
        {
          zhUrl: api + "/product%2Fled_8.png",
          enUrl: api + "/product%2Fled_8.png",
          zhSubUrl: api + "/product%2Fled_sub8.jpg",
          enSubUrl: api + "/product%2Fled_sub8.jpg",
          zhName: "LED T8 铝塑管",
          enName: "LED T8 aluminum-plastic tube"
        },
        {
          zhUrl: api + "/product%2Fled_9.png",
          enUrl: api + "/product%2Fled_9.png",
          zhSubUrl: api + "/product%2Fled_sub9.jpg",
          enSubUrl: api + "/product%2Fled_sub9.jpg",
          zhName: "LED T泡",
          enName: "LED T bubble"
        },
        {
          zhUrl: api + "/product%2Fled_10.png",
          enUrl: api + "/product%2Fled_10.png",
          zhSubUrl: api + "/product%2Fled_sub10.jpg",
          enSubUrl: api + "/product%2Fled_sub10.jpg",
          zhName: "LED 飞碟灯",
          enName: "LED UFO light"
        },
        {
          zhUrl: api + "/product%2Fled_11.png",
          enUrl: api + "/product%2Fled_11.png",
          zhSubUrl: api + "/product%2Fled_sub11.jpg",
          enSubUrl: api + "/product%2Fled_sub11.jpg",
          zhName: "LED 橄榄灯",
          enName: "LED olive light"
        },
        {
          zhUrl: api + "/product%2Fled_12.png",
          enUrl: api + "/product%2Fled_12.png",
          zhSubUrl: api + "/product%2Fled_sub12.jpg",
          enSubUrl: api + "/product%2Fled_sub12.jpg",
          zhName: "LED 鳍片水滴款",
          enName: "LED fin water drop"
        },
        {
          zhUrl: api + "/product%2Fled_13.png",
          enUrl: api + "/product%2Fled_13.png",
          zhSubUrl: api + "/product%2Fled_sub13.jpg",
          enSubUrl: api + "/product%2Fled_sub13.jpg",
          zhName: "LED 射灯类",
          enName: "LED spotlights"
        },
        {
          zhUrl: api + "/product%2Fled_14.png",
          enUrl: api + "/product%2Fled_14.png",
          zhSubUrl: api + "/product%2Fled_sub14.jpg",
          enSubUrl: api + "/product%2Fled_sub14.jpg",
          zhName: "LED 十字灯",
          enName: "LED cross light"
        },
        {
          zhUrl: api + "/product%2Fled_15.png",
          enUrl: api + "/product%2Fled_15.png",
          zhSubUrl: api + "/product%2Fled_sub15.jpg",
          enSubUrl: api + "/product%2Fled_sub15.jpg",
          zhName: "LED 小面板灯",
          enName: "LED small panel light"
        },
        {
          zhUrl: api + "/product%2Fled_16.png",
          enUrl: api + "/product%2Fled_16.png",
          zhSubUrl: api + "/product%2Fled_sub16.jpg",
          enSubUrl: api + "/product%2Fled_sub16.jpg",
          zhName: "LED 小面板灯",
          enName: "LED small panel light"
        },
        {
          zhUrl: api + "/product%2Fled_17.png",
          enUrl: api + "/product%2Fled_17.png",
          zhSubUrl: api + "/product%2Fled_sub17.jpg",
          enSubUrl: api + "/product%2Fled_sub17.jpg",
          zhName: "LED 小柱泡",
          enName: "LED small column bubble"
        },
        {
          zhUrl: api + "/product%2Fled_18.png",
          enUrl: api + "/product%2Fled_18.png",
          zhSubUrl: api + "/product%2Fled_sub18.jpg",
          enSubUrl: api + "/product%2Fled_sub18.jpg",
          zhName: "LED 自主学习组网雷达感应灯",
          enName: "LED self-learning networking radar sensor light"
        },
        {
          zhUrl: api + "/product%2Fled_19.png",
          enUrl: api + "/product%2Fled_19.png",
          zhSubUrl: api + "/product%2Fled_sub19.jpg",
          enSubUrl: api + "/product%2Fled_sub19.jpg",
          zhName: "LED鸟笼灯",
          enName: "LED bird cage light"
        }
      ],
      backSwiperOption: {
        // direction: "horizontal", //默认horizontal, 水平轮播
        loop: true, //循环
        slidesPerView: 4,
        spaceBetween: 10,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },

        pagination: {
          el: ".swiper-pagination",
          clickable: true
          // type:'fraction'
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        on: {
          init: function () {
            _this.changeInit(this.realIndex);
            _this.activeIndex = this.realIndex;
            const fraction = document.querySelector(".swiperNum");
            fraction.innerHTML = `${this.realIndex + 1} / ${
              _this.mainList.length
            }`;
          },
          slideChange: function () {
            _this.changeSlideChange(this.realIndex);
            _this.activeIndex = this.realIndex;
            const fraction = document.querySelector(".swiperNum");
            fraction.innerHTML = `${this.realIndex + 1} / ${
              _this.mainList.length
            }`;
          },
          click: function () {
            if (this.clickedIndex) {
              let initIndex =
                this.clickedIndex - this.activeIndex + this.realIndex;
              console.log("计算钱", initIndex);
              let index =
                initIndex >= _this.mainList.length
                  ? initIndex - _this.mainList.length
                  : initIndex;
              console.log("计算后", index);

              _this.changeSlideChange(index);

              // this.activeIndex = index + 4;
              // this.realIndex = index;
              _this.activeIndex = index;

              const fraction = document.querySelector(".swiperNum");
              fraction.innerHTML = `${index + 1} / ${_this.mainList.length}`;
            }
          }
        }
      }
    };
  },
  mounted() {},
  methods: {
    on_bot_enter() {
      this.$refs.mySwipers.swiper.autoplay.stop();
    },
    on_bot_leave() {
      this.$refs.mySwipers.swiper.autoplay.start();
    },
    handleSwiperItem(item, index) {
      console.log(1111111, this.$refs.slideItem);
      this.activeIndex = index;
      this.activeItem = item;
    },
    changeInit(realIndex) {
      const dom = document.getElementById("mySwipers");
      const elsAdd = dom.getElementsByClassName(`index${realIndex}`);
      for (let i = 0; i < elsAdd.length; i++) {
        const child = elsAdd[i];
        child.classList.add("activeimg_con");
      }
      const elsAdda = dom.getElementsByClassName(`indexa${realIndex}`);
      for (let i = 0; i < elsAdda.length; i++) {
        const child = elsAdda[i];
        child.classList.add("active_name");
      }
    },
    changeSlideChange(realIndex) {
      const dom = document.getElementById("mySwipers");
      const els = dom.getElementsByClassName("allImg");
      for (let i = 0; i < els.length; i++) {
        const child = els[i];
        child.classList.remove("activeimg_con");
        child.classList.remove("active_name");
      }

      const elsAdd = dom.getElementsByClassName(`index${realIndex}`);
      for (let i = 0; i < elsAdd.length; i++) {
        const child = elsAdd[i];
        child.classList.add("activeimg_con");
      }
      const elsAdda = dom.getElementsByClassName(`indexa${realIndex}`);
      for (let i = 0; i < elsAdda.length; i++) {
        const child = elsAdda[i];
        child.classList.add("active_name");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.product_con {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    color: #000000;
    font-size: 32px;
    font-weight: bold;
    font-style: normal;
    text-transform: none;
    font-family: MicrosoftYaHei, MicrosoftYaHei;
    margin: 40px 0 10px 0;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 60%;
      height: 8px;
      background: #96c33b;
      position: absolute;
      right: 0;
      bottom: 3px;
      z-index: -1;
    }
  }
  .subTitle {
    color: #666666;
    font-size: 11px;
  }
  .main_con {
    margin: 20px 0;
    position: relative;
    .swiper-button-next1 {
      right: -7%;
      top: 37%;
    }
    .swiper-button-prev1 {
      left: -7%;
      top: 37%;
    }
    .swiper-button-next2 {
      right: -10%;
      top: 28%;
    }
    .swiper-button-prev2 {
      left: -10%;
      top: 28%;
    }
    .swiper-button-next {
      background-image: url("https://website-1308146035.cos.ap-shanghai.myqcloud.com/right.png");
      background-size: 26px 26px;
    }
    .swiper-button-prev {
      background-image: url("https://website-1308146035.cos.ap-shanghai.myqcloud.com/left.png");
      background-size: 26px 26px;
    }
    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;

      color: #fff;
      // background: red;
    }
    .swiperNum2 {
      position: absolute;
      left: 0;
      bottom: 3.5%;
      z-index: 999999;
      color: #9c9c9c;
    }
    .swiperNum1 {
      position: absolute;
      left: 0;
      bottom: 3.5%;
      z-index: 999999;
      color: #9c9c9c;
    }
    .main_swiper1 {
      width: 550px;
      height: 210px;
    }
    .main_swiper2 {
      width: 80vw;
      height: 150px;
    }
    .main_swiper {
      .swiper-slide1 {
        width: 100px;
        height: 170px;
      }
      .swiper-slide2 {
        width: 50px;
        height: 110px;
      }
      .swiper-slide {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .img_con1 {
          width: 100px;
          height: 100px;
        }
        .img_con2 {
          width: 60px;
          height: 60px;
        }
        .activeimg_con {
          background: #96c33b;
        }
        .img_con {
          border: 1px solid #96c33b;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;
          .main_img {
            width: 80%;
            height: 80%;
          }
        }
        .active_name {
          color: #96c33b;
        }
        .img_name {
          width: 100%;
          font-size: 11px;

          white-space: nowrap;
          margin-top: 10px;
          overflow: hidden;
          margin: 0 auto;
          display: flex;
          justify-content: center;
        }
      }
      ::v-deep {
        .swiper-pagination1 {
          bottom: -2%;
        }
        .swiper-pagination2 {
          bottom: -20%;
        }

        .swiper-pagination-bullet {
          width: 15px;
          height: 4px;
          border-radius: 3px;
          background-color: #cdcecf;
        }
        .swiper-pagination-bullet-active {
          background-color: #96c33b;
        }
      }
    }
  }
  .detail_con {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
</style>
