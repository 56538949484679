var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product_con"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$parent.switchLanguage == "zh" ? "明星产品" : "STAR PRODUCTS")+" ")]),_c('div',{staticClass:"main_con",on:{"mouseenter":_vm.on_bot_enter,"mouseleave":_vm.on_bot_leave}},[_c('swiper',{ref:"mySwipers",staticClass:"main_swiper",class:_vm.$parent.watchClientWidth > 600 ? 'main_swiper1' : 'main_swiper2',attrs:{"id":"mySwipers","options":_vm.backSwiperOption}},[_vm._l((_vm.mainList),function(item,index){return _c('swiper-slide',{key:index,staticClass:"swiper-slide",class:_vm.$parent.watchClientWidth > 600 ? 'swiper-slide1' : 'swiper-slide2'},[_c('div',{ref:"slideItem",refInFor:true,staticClass:"img_con",class:_vm.$parent.watchClientWidth > 600
              ? `img_con1 allImg index${index}`
              : `img_con2 allImg index${index}`},[_c('img',{staticClass:"main_img",attrs:{"kyIndex":index,"src":_vm.$parent.switchLanguage == 'zh' ? item.zhUrl : item.enUrl,"alt":""}})]),_c('div',{staticClass:"img_name",class:`allImg indexa${index}`},[_vm._v(" "+_vm._s(_vm.$parent.switchLanguage == "zh" ? item.zhName : item.enName)+" ")])])}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2),_c('div',{staticClass:"swiper-button-prev",class:_vm.$parent.watchClientWidth > 600
          ? 'swiper-button-prev1'
          : 'swiper-button-prev2',attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",class:_vm.$parent.watchClientWidth > 600
          ? 'swiper-button-next1'
          : 'swiper-button-next2',attrs:{"slot":"button-next"},slot:"button-next"}),_c('div',{staticClass:"swiperNum",class:_vm.$parent.watchClientWidth > 600 ? 'swiperNum2' : 'swiperNum1'})],1),_c('div',{staticClass:"detail_con"},[_c('img',{attrs:{"src":_vm.$parent.switchLanguage == 'zh'
          ? _vm.mainList[_vm.activeIndex ? _vm.activeIndex : 0].zhSubUrl
          : _vm.mainList[_vm.activeIndex ? _vm.activeIndex : 0].enSubUrl,"alt":""}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }